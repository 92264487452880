import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';
import Icon from './icon';
import { mq } from './_shared/media';
import { StyledH1 } from './_shared/styled-headings';
import { StyledSection } from './_shared/styled-section';

const StyledTextSection = styled.section`
  white-space: pre-line;
`;
const StyledFeatureGridContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2.5rem;
  width: 100%;
`;

const StyledFeatureCard = styled.article`
  border: 1px solid var(--body-color);
  border-radius: var(--radius);
  background-color: var(--bg-color);

  display: block;
  align-items: center;
  text-align: center;
  flex-direction: column;
  min-height: 100px;
  max-width: 43%;
  margin: 2%;
  padding: 1rem;
  padding-top: 2rem;
  transition: transform var(--transition-slow);

  

  &:hover{
    transform: scale(1.2);
  }

  & svg {
    height: 2rem;
    width: 2rem;
    font-size: 2rem;
  }
  ${mq.gt.xs} {
    width: 29%;
  }
  ${mq.gt.sm} {
    width: 21%;
  }
`;
const StyledCardLabel = styled.h2`
  font-size: 0.9rem;
  margin: auto;
  color: var(--body-color);
  vertical-align: middle;
  margin-top: 1.5rem;
  width: 80%;
`;
const StyledDivider = styled.div`
  width: 30%;
  height: 2px;
  background-color: var(--primary-color);
  position: relative;
  top: 10%;
  margin: auto;
`;
const StyledFeatureDescription = styled.p`
  font-size: 0.8rem;
`;

const CardGrid = ({ cards, description, title, id = null }) => {
  const featureCards = cards.map(({ icon, prefix, label, description }, index) => {
    return (
      <StyledFeatureCard key={index}>
        <Icon icon={icon} prefix={prefix} />
        <StyledDivider></StyledDivider>
        <StyledCardLabel>{label}</StyledCardLabel>
        {/* <StyledFeatureDescription>{description}</StyledFeatureDescription> */}
      </StyledFeatureCard>
    );
  });

  return (
    <StyledSection id='tech'>
      {title && <StyledH1>{title}</StyledH1>}
      <StyledTextSection dangerouslySetInnerHTML={{ __html: description }} />
      <StyledFeatureGridContainer>{featureCards}</StyledFeatureGridContainer>
    </StyledSection>
  );
};

CardGrid.propTypes = {
  cards: PropTypes.array.isRequired,
  description: PropTypes.string.isRequired,
  id: PropTypes.string,
  title: PropTypes.string,
};

export default CardGrid;
