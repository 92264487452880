import styled from '@emotion/styled';
import React from 'react';
import Icon from './icon';
import { mq } from './_shared/media';
import { StyledH1 } from './_shared/styled-headings';
import { StyledSection } from './_shared/styled-section';

const StyledContactSection = styled(StyledSection)`
  margin-bottom: 50px;
`;
const StyledContainer = styled.a`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  padding-left: 0.75rem;

  border-radius: var(--radius);
  background-color: var(--bg-content-color);

  transition: transform var(--transition-fast);

  &:not(:hover){
    color: lightgray;

    div{
      color: var(--primary-color);
    }

  }

  &:hover{
    transform: scale(1.1);
  }

  & > svg {
    font-size: 2rem;
    margin-right: 0.75rem;
  }
`;
const StyledFormContainer = styled.section`
  & > span {
    font-size: 0.75rem;
    font-weight: 500;
  }



  &>a{
    color: var(--primary-color);
    font-weight: normal;
    width: 100%;
    border: 1px solid red;
  }
`;
const StyledForm = styled.div`
  color: var(--primary-color);

  font-weight: normal;
`;
const StyledTextSection = styled.section`
  white-space: pre-line;
  width: 100%;
  max-width: 500px;
  margin-left: 3px;
`;
const StyledSeparator = styled.div`
  height: 1px;
  width: 50%;
  background-color: var(--body-color);
`;
const StyledContacts = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 30px;
  width: 100%;
  margin-top: 2rem;

  ${mq.gt.xs} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${mq.gt.sm} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const Contact = ({ data }) => {
  const {
    frontmatter: { phone, email, address },
    html,
  } = data;
  const addressLink = 'http://maps.google.com/?q='+address;
  const emailLink = 'mailto:'+email;
  const phoneLink =  'tel:'+phone;
  return (
    <React.Fragment>
      <StyledContactSection id="contact">
        <StyledH1>Contact Details</StyledH1>
        <StyledTextSection dangerouslySetInnerHTML={{ __html: html }} />
        <StyledSeparator />
        <StyledContacts>
          {address && (
            <StyledContainer href={addressLink} target={"_blank"}>
              <Icon icon="building" />
              <StyledFormContainer>
                <div href={addressLink} target={"_blank"}>Office Location</div>
                <span>{address}</span>
              </StyledFormContainer>
            </StyledContainer>
          )}
          {email && (
            <StyledContainer href={emailLink}>
              <Icon icon="paper-plane" />
              <StyledFormContainer>
                <div href={emailLink} target={"_blank"}>My E-Mail</div>
                <span>{email}</span>
              </StyledFormContainer>
            </StyledContainer>
          )}
          {phone && (
            <StyledContainer href={phoneLink}>
              <Icon icon="mobile-alt" />
              <StyledFormContainer>
                <div href={phoneLink}>Phone Number</div>
                <span>{phone}</span>
              </StyledFormContainer>
            </StyledContainer>
          )}
        </StyledContacts>
      </StyledContactSection>
    </React.Fragment>
  );
};

export default Contact;
