import styled from '@emotion/styled';
import React, { useState } from "react";
import {useForm} from "react-hook-form";
import TextLink from './links/text-link';
import { StyledH1 } from './_shared/styled-headings';
import ReCAPTCHA from "react-google-recaptcha";

const API_GATEWAY_URL = "https://n59kt0c56b.execute-api.us-east-1.amazonaws.com/prod";
const required = "* This field is required";

const FormContainer = styled.div`
    background-color: var(--bg-content-color);
    border-radius: var(--radius);
    width: 50%;
    margin: auto;
    display: block;
    padding: 3rem;
    color: var(--primary-color);
    transition: transform 0.5s;
    
    & h5{
        color: var(--primary-color);
    }

    & h5, button{
        margin-top: 2rem;
    }

    & textarea,input{
        font-family 'Arial', sans-serif;
        font-size: 16px;
        padding: 5px;
    }

    & *{
        width: 100%;
        margin: auto;
    }
`;

export const StyledButton = styled.button`
  text-decoration: none;
  color: var(--bg-content-color);
  background-color: var(--title-color);
  font-size: 0.9rem;
  font-weight: 500;
  white-space: nowrap;
  position: relative;
  border: none;
  padding: 0.4rem 0.8rem;

  &:hover {
    color: var(--secondary-color) !important;
  }
`;

export default () => {
  const [submitted, setSubmitted] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    errors,
    reset,
    formState: { isSubmitting }
  } = useForm();

  const onSubmit = async data => {
    try {
      await fetch(API_GATEWAY_URL, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        body: JSON.stringify(data),
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      });
      setSubmitted(true);
      reset();
    } catch (error) {
      setError(
        "submit",
        "submitError",
        `Oops! There seems to be an issue! ${error.message}`
      );
    }
  };

  const showSubmitError = msg => <p>{msg}</p>;

  const showThankYou = (
    <div>
      <p>Awesome! Your message was sent.</p>
      <StyledButton type="button" onClick={() => setSubmitted(false)}>
        Send another message?
      </StyledButton>
      <TextLink label="Go Home" link="/"></TextLink>
    </div>
  );

  function enable(){
    document.getElementById('formButton').removeAttribute('hidden');
  }
  
  const showForm = (
        <form onSubmit={handleSubmit(onSubmit)} method="post">
          <input hidden name="source" id="rakkaton" value="rakkaton"></input>
            <label>
                <h5>Name</h5>
                <input
                type="text"
                name="name"
                id="name"
                placeholder="Your name"
                ref={register({ required })}
                // value='Name test'
                disabled={isSubmitting}
                />
                {errors.name && <div>{errors.name.message}</div>}
            </label>

            <label>
                <h5>Email</h5>
                <input
                type="email"
                name="email"
                id="email"
                placeholder="your@email.address"
                ref={register({ required })}
                // value='Email@test.com'
                disabled={isSubmitting}
                />
                {errors.email && (
                <div>{errors.email.message}</div>
                )}
            </label>

            <label>
                <h5>Message</h5>
                <textarea
                ref={register({ required })}
                name="question"
                id="question"
                rows="3"
                // value='Message test'
                placeholder="Your message"
                disabled={isSubmitting}
                />
                {errors.question && (
                <div>{errors.question.message}</div>
                )}
            </label>
            <div>
              <ReCAPTCHA
                sitekey="6LfJ3S0aAAAAADgPTcozbK09bSqxnARHS0ypJZsz"
                onChange={enable}
              />
                <StyledButton id='formButton' type="submit" hidden disabled={isSubmitting}>
                    Send
                </StyledButton>
            </div>
        </form>
  );

  return (
    <FormContainer>
        <StyledH1>Contact us</StyledH1>
        {errors && errors.submit && showSubmitError(errors.submit.message)}
        {submitted ? showThankYou : showForm}
    </FormContainer>
  );
};
