import styled from '@emotion/styled';
import Img from 'gatsby-image';
import React from 'react';
import TechList from './tech-list';
import { mq } from './_shared/media';
import { StyledH1, StyledH2 } from './_shared/styled-headings';
import { StyledStaticImageContainer } from './_shared/styled-image-container';
import { StyledSection } from './_shared/styled-section';

const StyledAboutContainer = styled.article`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 2.5rem;
  padding: 2.5rem 0;

  ${mq.gt.sm} {
    grid-template-columns: repeat(2, 1fr);
  }
`;
const StyledTextSection = styled.section`
`;

const StyledTextP = styled.p`
  margin: 0;
  padding-left: 10%;
  font-size: 14px;
`;

const StyledTextH3 = styled.h3`
  margin: 0;
  font-size: 24px;

  mark{
    background-color: transparent;
    color: var(--primary-color);
  }
`;

const StyledParagraph = styled.p`
  font-size: 20px;
  font-weight: 1000;
`;

const StyledNote = styled.div`
  transition: transform var(--transition-normal);
  padding-top: 1.5em;
  padding-bottom: 1.5em;

  &:hover{
    transform: scale(1.1);
  }
`;

const About = ({ data }) => {
  const {
    frontmatter: { title, techs, about_image, about_image2 },
    html,
  } = data;

  const image = about_image ? about_image.childImageSharp.fluid : null;
  const image2 = about_image2 ? about_image2.childImageSharp.fluid : null;

  return (
    <StyledSection id="about">
      <StyledH1>Our Mission</StyledH1>
      <StyledAboutContainer>
        {image && (
          <StyledStaticImageContainer>
            <Img fluid={image} objectFit="contain" />
          </StyledStaticImageContainer>
        )}
        <div>
          <StyledH2>{title}</StyledH2>
          <StyledTextSection/>
            <StyledParagraph>Rakkaton is a premier international reseller of open-box home improvement products via integrated and innovative services by providing</StyledParagraph>
            <StyledNote>
              <StyledTextH3>- Best <mark>channels</mark></StyledTextH3>
              <StyledTextP>International export channel partners</StyledTextP>
            </StyledNote>

            <StyledNote>
              <StyledTextH3>- Best <mark>technology</mark></StyledTextH3>
              <StyledTextP>Industry vertical specialized technology</StyledTextP>
            </StyledNote>

            <StyledNote>
              <StyledTextH3>- Best <mark>service</mark></StyledTextH3>
              <StyledTextP>A highly optimized supply chain services</StyledTextP>
            </StyledNote>

            <StyledNote>
              <StyledTextH3>- Best <mark>team</mark></StyledTextH3>
              <StyledTextP>With global experience</StyledTextP>
            </StyledNote>
          {/* <TechList techs={techs} /> */}
        </div>
      </StyledAboutContainer>
      <StyledAboutContainer>
          <StyledTextSection>
            <StyledParagraph>
              Simplifying the sale of liquidation and overstock product.
            </StyledParagraph>
            <StyledParagraph>
              At Rakkaton, we focus on helping our international import/export channel partners acquire the best product for their local market needs by optimizing product sourcing, product grading and testing, recurring sales management and export preparation.
            </StyledParagraph>
            <StyledNote>
              <StyledTextH3>- Clear <mark>Quality Standards</mark></StyledTextH3>
            </StyledNote>
            <StyledNote>
              <StyledTextH3>- Compliant <mark>International Supply Chain</mark></StyledTextH3>
            </StyledNote>
            <StyledNote>
              <StyledTextH3>- Market <mark>Know-How</mark></StyledTextH3>
            </StyledNote>
          </StyledTextSection>
          {image && (
            <Img fluid={image2} objectFit="contain" />
        )}
      </StyledAboutContainer>
    </StyledSection>
  );
};

About.propTypes = {};

export default About;



// <h3>Best channels</h3>
// <p>to recurring offshore buyers</p>
// <h3>Best technology</h3>
// <p>that will help procure, manifest, export and sell products</p>
// <h3>Best service</h3>
// <p>that is of consistent, global quality</p>
// <h3>Best team</h3>
// <p>with global experience</p>